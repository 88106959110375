import { createContext, useContext, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import {
  fetchMNPActivationHolidayConfig,
  fetchNewNumberActivationConfig,
} from "../../../apis";
import { CheckoutFormContext } from "./CheckoutFormContext";
import { DateRangeHelper } from "../../../utils/dateRange";
import dayjs from "dayjs";

const defaultContext = {};

export const PlanActivationContext = createContext(defaultContext);

export default function PlanActivationProvider({ children }) {
  const { calendarQuery: publicHolidayCalendarQuery } =
    useContext(CheckoutFormContext);

  const [newNumberConfig, mnpHolidaysConfig] = useQueries({
    queries: [
      {
        queryKey: ["fetchNewNumberActivationConfig"],
        queryFn: fetchNewNumberActivationConfig,
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["mnpActiviationHolidays"],
        queryFn: fetchMNPActivationHolidayConfig,
        refetchOnWindowFocus: false,
      },
    ],
  });

  const getDateRangeHelper = (props) => {
    const { minDayKey, maxDayKey, ignores=[] } = props;

    const minDay =
      newNumberConfig.data?.data?.find((e) => e.keyPath === minDayKey)?.value ||
      5;
    const maxDay =
      newNumberConfig.data?.data?.find((e) => e.keyPath === maxDayKey)?.value ||
      14;

    const helper = new DateRangeHelper(
      dayjs().startOf("day"),
      minDay,
      maxDay,
      {
        day: [0, 6], // weekend
        otherDates: publicHolidayCalendarQuery.data?.data.concat(ignores) ?? [],
      }
    );

    return helper;
  };

  const newNumberDateRangeHelper = useMemo(
    () =>
      getDateRangeHelper({
        minDayKey: "plan.newNumberActivationMinDay",
        maxDayKey: "plan.newNumberActivationMaxDay",
      }),
    [newNumberConfig.data, publicHolidayCalendarQuery.data?.data]
  );

  const mnpNumberDateRangeHelper = useMemo(
    () =>
      getDateRangeHelper({
        minDayKey: "plan.mnpNumberActivationMinDay",
        maxDayKey: "plan.mnpNumberActivationMaxDay",
        ignores:
          mnpHolidaysConfig?.data?.data?.find(
            (item) => item?.keyPath === "plan.mnpActivationHolidays"
          )?.value || [],
      }),
    [newNumberConfig.data, mnpHolidaysConfig.data, publicHolidayCalendarQuery.data?.data]
  );

  return (
    <PlanActivationContext.Provider
      value={{ newNumberDateRangeHelper, mnpNumberDateRangeHelper }}
    >
      {children}
    </PlanActivationContext.Provider>
  );
}
