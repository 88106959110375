import { forwardRef, useContext, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider, yupToFormErrors, validateYupSchema } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import StepContainer from "../StepContainer";
import { addNumberToPlan } from "../../../apis";
import { appearGlobalError } from "../../../requests/globalRequest";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import { PlanActivationContext } from "../context/PlanActivationContext";
import { CheckoutFormContext } from "../context/CheckoutFormContext";
import dayjs from "dayjs";

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export const CART_ITEM_TYPE = {
  PLAN: "plan",
  DEVICE: "device",
  DELIVERY: "delivery",
}

const getInitialValues = (data) => {
  const orderList = data?.items || [];
  const initialValues = orderList?.map((item) => {
    return {
      ...item,
      // only newNumQty shared for both plan and device
      order_newNumQty:
        item?.newNumbers?.length > 0
          ? item?.newNumbers?.length
          : item?.quantity - item?.mnpNumbers?.length,
      // exclusive to plan only, should be default value if the type is plan

      order_newNumList: item?.newNumbers ? item.newNumbers.map(num => ({ ...num, _id: num.numberId })) : [],
      order_newNumbersEffectiveDate: item?.newNumberEffectiveDate || "",
      order_mnpNumQty: item?.mnpNumbers?.length || 0,
      order_mnpNumbers: item?.mnpNumbers || [],
      order_isAutoRenewal: _.isBoolean(item?.plan?.planAutoRenewalOption) ? item?.plan?.planAutoRenewalOption : true,
    };
  });

  return {
    order_list: initialValues,
    coupon_code: data?.promoCode || "",
    helperFields: {
      shouldRedirect: false
    }
  };
};

const processBody = (data) => {
  const body = data?.map((item) => {
    let mnpList = [];

    if (item.order_mnpNumbers) {
      mnpList = item?.order_mnpNumbers.map((nums) => {
        let mnpNumberBody = {
          number: nums.number,
          simType: nums.simType,
          realNameRegistered: nums.realNameRegistered,
          effectiveDate: nums.effectiveDate,
          effectiveTime: nums.effectiveTime,
          agreeChangeOwner: nums.agreeChangeOwner,
          acceptedPlanMnpTnc: nums.acceptedPlanMnpTnc,
          unableToProvideOTP: nums.unableToProvideOTP,
          phoneOtpId: nums.phoneOtpId,
          networkOperator: nums.networkOperator,
        };

        if (nums.simType === "postpaid") {
          delete mnpNumberBody.acceptedPrepaidTnc;
          delete mnpNumberBody.realNameRegistered;
        } else {
          delete mnpNumberBody.acceptedPostpaidTnc;
          if (nums.realNameRegistered !== true) {
            delete mnpNumberBody.acceptedPrepaidTnc;
          }
        }

        return mnpNumberBody;
      });
    }

    let newNumList = {};

    if (item.order_newNumQty > 0) {
      newNumList = {
        newNumbersEffectiveDate: item.order_newNumbersEffectiveDate,
      };
    }

    return {
      ...newNumList,
      newNumberPoolIds: item.order_newNumQty > 0 ?
        item.order_newNumList ?
          item.order_newNumList.map((item) => item._id)
          : []
        : [],
      orderId: item?._id,
      isAutoRenewal: item?.order_isAutoRenewal || false,
      mnpNumbers: mnpList,
    };
  });

  return body;
};

const validationSchema = Yup.object().shape({
  order_list: Yup.array().of(
    Yup.object({
      type: Yup.string(),
      order_newNumList: Yup.array().when(["type", "order_newNumQty"], (data, schema) => {
        const type = data?.[0];
        const val = data?.[1];
        return type === CART_ITEM_TYPE.PLAN && val > 0
          ? schema.min(val, "checkout:warnPleaseChooseNumber").required("error:required")
          : schema;
      }),
      order_mnpNumbers: Yup.array().when(["type", "order_mnpNumQty"], (data, schema) => {
        const type = data?.[0];
        const val = data?.[1];
        return type === CART_ITEM_TYPE.PLAN && val > 0
          ? schema.of(
            Yup.object().shape({
              number: Yup.string().required("error:required"),
              effectiveDate: Yup.string()
                                .required("error:required")
                                .test('isValidDate', 'error:invalidDate', function (value) {
                                  const { mnpNumberMinDay, mnpNumberMaxDay } = this.options.context;
                                  // day is valid only if it is between min and max day, inclusive
                                  const result = dayjs(value, "DD/MM/YYYY")
                                                  .isBetween(mnpNumberMinDay, mnpNumberMaxDay, "day", '[]');
                                  return result;
                                }),
            })
          ).min(val, "checkout:warnPleaseChooseNumber").required("error:required")
          : schema;
      }),
      order_newNumbersEffectiveDate: Yup.string().when(["type", "order_newNumQty"], {
        is: (type, val) => type === CART_ITEM_TYPE.PLAN && val > 0,
        then: (schema) => schema
                            .required("error:required")
                            .test('isValidDate', 'error:invalidDate', function (value) {
                              const { newNumberMinDay, newNumberMaxDay } = this.options.context;
                              // day is valid only if it is between min and max day, inclusive
                              const result = dayjs(value, "DD/MM/YYYY")
                                              .isBetween(newNumberMinDay, newNumberMaxDay, "day", '[]');
                              return result;
                            }),
      }),
    })
  )
})

const OrderListForm = forwardRef(({
  children,
  data,
  step,
  currentStep,
  setStep,
  refetch,
  onNext,
}, ref) => {
  
  const { t } = useTranslation("checkout");
  const { globalDispatch } = useContext(GlobalContextStore);
  const { isDeviceOnly } = useContext(CheckoutFormContext)
  const { newNumberDateRangeHelper, mnpNumberDateRangeHelper } = useContext(PlanActivationContext);

  const [skipValidation, setSkipValidation] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(data),
    // validationSchema: !skipValidation ? validationSchema : undefined,
    validate: (values) => {
      if (skipValidation) return {};

      try {
        validateYupSchema(
          values,
          validationSchema,
          true,
          {
            newNumberMinDay: newNumberDateRangeHelper?.startDate,
            newNumberMaxDay: newNumberDateRangeHelper?.endDate,
            mnpNumberMinDay: mnpNumberDateRangeHelper?.startDate,
            mnpNumberMaxDay: mnpNumberDateRangeHelper?.endDate,
          }
        );
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload, { validateForm }) => {
      console.log("submit order list", payload);

      if (isDeviceOnly) {
        onNext && onNext();
        return;
      }

      // with plan
      const numberBody = processBody(_.filter(payload.order_list, ["type", "plan"]));
      console.log("save order list", numberBody);

      let result = await Promise.all(
        numberBody.map(async (item) => {
          const { orderId, ...remaining } = item;
          return addNumberToPlan(remaining, orderId);
        })
      );

      const errorsInAddNumber = result.filter((item) => !!item?.result?.errorMessage);
      if (errorsInAddNumber.length > 0) {
        throw errorsInAddNumber[0];
      }

      if (result) {
        refetch && refetch();
      }
      if (payload.helperFields.shouldRedirect) {
        onNext && onNext();
      }
    },
  });

  const handleSave = async (shouldRedirect=false) => {
    try {
      await formik.setValues({ ...formik.values, helperFields: { shouldRedirect } })
      await formik.setErrors({})
      await formik.submitForm()
    } catch (e) {
      appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage, {
        type: "fail",
      });
    }
  }

  const handleClickSave = () => {
    setSkipValidation(true);
    handleSave();
  }

  const handleClickNext = () => {
    setSkipValidation(false);
    handleSave(true);
  }

  // auto scroll to error field
  useEffect(() => {
    if (formik?.errors?.order_list?.length > 0) {
      // first error 
      const firstErrorIndex = formik.errors.order_list.findIndex((item) => item);
      const element = document.getElementsByName(`order_list[${firstErrorIndex}]`)[0];
      element && element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formik.errors?.order_list])

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepContainer
          step={step}
          setStep={setStep}
          currentStep={currentStep}
          title={t("orderInformation")}
          onSave={handleClickSave}
          onProceedNextStep={handleClickNext}
          ref={ref}
          isLoading={formik.isSubmitting}
        >
          {children}
        </StepContainer>
      </Form>
    </FormikProvider>
  );
});

export default OrderListForm;
