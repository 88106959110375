import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalErrorDialog from "./components/CommonDialog/GlobalErrorDialog";
import Footer from "./components/Footer";
import GlobalProvider from "./context/GlobalProvider";
import Routes from "./Routes";
import { initGA } from "./utils/ga";
import { initGTM } from "./utils/gtm";

import "@fontsource/roboto";
import Metadata from "./components/Metadata";
import ScrollableAppbar from "./components/NavBar";
import ScrollToTop from "./utils/ScrollToTop";

const queryClient = new QueryClient();

function App() {
  /* Google Analytics & Google Tag Manager */
  useEffect(() => {
    initGA();
    initGTM();

    // version code
    console.log("Version: 1.1.0");
  }, [])

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ScrollableAppbar />
      <ScrollToTop />
      <Metadata />
      <Routes />
      <Footer />
      <GlobalErrorDialog />
    </div>
  );
}

const AppWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppWrapper;
