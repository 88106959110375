import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import YellowBubbleCheckbox from '../../../../components/Checkboxs/YellowBubbleCheckbox';
import DatePicker from '../../../../components/DateTimePicker/DatePicker';
import NumberInput from '../../../../components/NumberInput';
import Select from '../../../../components/Select';
import Space from '../../../../components/Space';
import ErrorText from '../../../../components/Typographies/ErrorText';
import MyTypography from '../../../../components/Typography';
import theme from '../../../../theme';
import { breakpoint } from '../../../../utils/responsive';
import { CheckoutFormContext } from '../../context/CheckoutFormContext';
import NewNumberDialog from './NewNumberDialog';
import { PlanActivationContext } from '../../context/PlanActivationContext';

export const NEW_NUMBER_EFFECTIVE_TIMESLOT = [
  {
    title: '9am - 1pm',
    value: 'am',
  },
  {
    title: '2pm - 6pm',
    value: 'pm',
  },
];


const NewNumber = ({ name, totalPlanNum, idx, required }) => {
  const { t } = useTranslation('checkout');
  const [field, meta, helpers] = useField(`${name}`);
  const { values } = useFormikContext();
  const [isOpenNemNum, setIsOpenNewNum] = useState(false);
  const { newNumberDateRangeHelper: dateRangeHelper } = useContext(PlanActivationContext);

  //update new number list if mnp number change
  useEffect(() => {
    const mnpNumQty = field.value?.order_mnpNumQty;
    const remain = totalPlanNum - mnpNumQty;
    const tempNumList =
      field.value.order_newNumList.length > remain
        ? _.dropRight(field.value.order_newNumList)
        : field.value.order_newNumList;

    helpers.setValue({
      ...field.value,
      order_newNumQty: remain,
      order_newNumList: tempNumList,
    });
  }, [field.value?.order_mnpNumQty]);

  // clear error if new number qty is 0
  useEffect(() => {
    if (field.value?.order_newNumQty === 0) {
      const { order_newNumList, order_newNumbersEffectiveDate, ...restErrors } = meta.error || {};
      helpers.setError(restErrors);
    }
  }, [field.value?.order_newNumQty]);

  // const newNumberFirstEffectiveDate = 

  const handleNumberOnChange = (number) =>
    helpers.setValue({ ...field.value, order_newNumQty: number });

  return (
    <Container>
      <SubContainer>
        <Quantity>
          <MyTypography bold>{t('newNumber')}</MyTypography>
          <NumberInput
            min={0}
            max={totalPlanNum}
            value={field?.value?.order_newNumQty}
            onChange={handleNumberOnChange}
          />
        </Quantity>
        {field?.value?.order_newNumQty > 0 && (
          <Content>
            <MyTypography fontSize="18" fontWeight="bold">
              <span>{t('chooseYourNewNumber')}</span>
              {required && <span style={{ color: theme.orange }}> *</span>}
            </MyTypography>
            <Space size="20px" />
            <Numbers>
              {field?.value?.order_newNumList?.map((num) => {
                return (
                  <YellowBubbleCheckbox
                    label={num?.number}
                    checked={true}
                    disableChange
                    style={{
                      // margin: 10,
                      width: 230,
                    }}
                  />
                );
              })}
            </Numbers>
            <Space size="25px" />
            <ChangeNumberButton onClick={() => setIsOpenNewNum(true)}>
              {t('changeNumber')}
            </ChangeNumberButton>
            {meta?.error?.order_newNumList && (
              <ErrorText>{t(meta?.error?.order_newNumList, { num: field?.value?.order_newNumQty })}</ErrorText>
            )}
            <Space size="50px" />
            <DatePicker
              id={`${name}.order_newNumbersEffectiveDate`}
              title={t('effectiveDateTime')}
              minDate={dateRangeHelper.startDate}
              maxDate={dateRangeHelper.endDate}
              shouldDisableDate={(date) => dateRangeHelper.shouldDisableDate(date)}
              required
            />
            <Space size="10px" />

          </Content>
        )}

        <NewNumberDialog
          name={name}
          isOpen={isOpenNemNum}
          setIsOpen={setIsOpenNewNum}
        />
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  // height: 100%;
`;

const SubContainer = styled.div`
  padding: 25px;
  @media ${breakpoint.desktop} {
    padding: 50px;
  }
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const ChangeNumberButton = styled(MyTypography)`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;

const Numbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const Content = styled.div`
  margin-top: 30px;

  @media ${breakpoint.mobile} {
    margin-top: 15px;
  }
`;

export default NewNumber;
