import { useEffect } from "react";
import styled from "styled-components";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import _, { isEmpty } from "lodash";

import NumberInput from "../../../../components/NumberInput";
import MyTypography from "../../../../components/Typography";
import Space from "../../../../components/Space";
import AddNumberBox from "./AddNumberBox";

import { breakpoint } from "../../../../utils/responsive";
import theme from "../../../../theme";

export const INITIAL_PORTIN = {
  number: "",
  otp: "",
  networkOperator: "",
  simType: "postpaid",
  effectiveDate: "",
  effectiveTime: "",
  realNameRegistered: true,
  unableToProvideOTP: false,
  acceptedPrepaidTnc: false,
  acceptedPostpaidTnc: false,
};

const ExistingNumber = ({ name, idx, totalPlanNum, tncData, required }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(`${name}`);

  //update mnp number list if new number change
  useEffect(() => {
    const newNumQty = field?.value?.order_newNumQty;
    const remain = totalPlanNum - newNumQty - field.value.order_mnpNumQty; //FIXME

    if (remain > 0) {
      const fill = Array(remain)
        .fill()
        .map((_, i) => INITIAL_PORTIN);
      helpers.setValue({
        ...field.value,
        order_mnpNumQty: field.value.order_mnpNumQty + remain,
        order_mnpNumbers: [...field.value.order_mnpNumbers, ...fill],
      });
    } else if (remain < 0) {
      helpers.setValue({
        ...field.value,
        order_mnpNumQty: field.value.order_mnpNumQty - 1,
        order_mnpNumbers: _.dropRight(field.value.order_mnpNumbers),
      });

      // drop error if exist
      if (meta?.error?.order_mnpNumbers?.length > 0) {
        const droppedError = _.dropRight(meta.error.order_mnpNumbers);
        const tempError = { 
          ...meta.error,
          order_mnpNumbers: droppedError.length > 0 ? droppedError : undefined,
        }

        helpers.setError(tempError);
      }
    }
  }, [field?.value?.order_newNumQty]);

  const handleNumberOnChange = (number) => {
    if (number > field.value.order_mnpNumQty) {
      helpers.setValue({
        ...field.value,
        order_mnpNumQty: field.value.order_mnpNumQty + 1,
        order_mnpNumbers: [...field.value.order_mnpNumbers, INITIAL_PORTIN],
      });
    } else {
      helpers.setValue({
        ...field.value,
        order_mnpNumQty: field.value.order_mnpNumQty - 1,
        order_mnpNumbers: field?.value?.order_mnpNumbers.slice(0, -1),
      });
    }
  };

  const handleDeleteNumber = (idx) => {
    let currentMnpNumbers = field.value.order_mnpNumbers;
    if (Array.isArray(currentMnpNumbers) && currentMnpNumbers.length > 0) {
      currentMnpNumbers[idx] = INITIAL_PORTIN;
      helpers.setValue({
        ...field.value,
        order_mnpNumbers: currentMnpNumbers,
      });
    }
  };

  return (
    <Container>
      <Quantity>
        <MyTypography bold>{t("mobilePortin")}</MyTypography>
        <NumberInput
          min={0}
          max={totalPlanNum}
          value={field?.value?.order_mnpNumQty}
          onChange={handleNumberOnChange}
        />
      </Quantity>
      {field?.value?.order_mnpNumQty > 0 && (
        <Content>
        <MyTypography fontSize="18" bold>
          <span>{t("mobileNumberPorted")}</span>
          {required && <span style={{ color: theme.orange }}> *</span>}
        </MyTypography>

        <Space size="20px" />

        <div style={{ display: "flex", flexDirection: "column" }}>
          {field?.value?.order_mnpNumbers?.map((item, idx) => {
            return (
              <AddNumberBox
                name={`${name}.order_mnpNumbers[${idx}]`}
                tncData={tncData}
                onDelete={handleDeleteNumber}
                idx={idx}
                error={!isEmpty(meta?.error?.order_mnpNumbers?.[idx])}
              />
            );
          })}
        </div>
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 25px;

  @media ${breakpoint.desktop} {
    padding: 50px;
  }
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  margin-top: 30px;

  @media ${breakpoint.mobile} {
    margin-top: 15px;
  }
`;

export default ExistingNumber;
