import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import Typography from "../Typography";
import _ from "lodash";

const StyledTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          boxShadow: "0 0 6px 0 #c9c9c9",
          paddingRight: 10,
          // fontSize: '18px',
          // fontWeight: 500,
          borderColor: "none",
          borderWidth: "0px",

          "&.Mui-error": {
            border: `1px solid ${theme.error3}`,
            boxShadow: `0 0 6px 0 ${theme.error3}`,
          },

          "&.Mui-disabled": {
            backgroundColor: "#f2f2f2",
            boxShadow: `0 0 6px 0 #c9c9c9`,
          },
        },
        notchedOutline: {
          borderColor: "transparent !important",
          borderWidth: "0px !important",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // FIXME: have better way to build the divider?
          "&.MuiSelect-icon": {
            marginRight: "10px",
            paddingLeft: "10px",
            borderLeft: `2px solid ${theme.divider}`,
          },
          "&.MuiSelect-iconOpen": {
            marginRight: "10px",
            padding: "0px 10px 0px 0px",
            borderLeft: "none",
            borderRight: `2px solid ${theme.divider}`,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-root": {
            marginTop: "5px",
            boxShadow: `0 0 10px 0 ${theme.shadowYellow}`,
            maxHeight: "300px", // TODO: to be confirmed
            borderRadius: "15px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "12px 23px",
          // fontWeight: 'bold',
          // fontSize: '18px',
          color: theme.lightBlack,
          "&.Mui-selected": {
            backgroundColor: "#fffbde !important",
            "&:hover": {
              backgroundColor: "#fffbde", // TODO
            },
          },
        },
      },
    },
  },
});

const CommonSelect = ({
  options=[],
  required = false,
  id="select",
  viewOnly,
  placeholder,
  value,
  onChange,
  error,
  title,
  helperText,
  hintText=null,
  multiLevel=false,
  ...props
}) => {
  const { t } = useTranslation(["common", "error"]);

  const viewOnlyValue = React.useMemo(() => {
    const usedOptions = multiLevel ? options.reduce((acc, subheader) => {
      acc.push(...subheader.options);
      return acc;
    }, []) : options;

    return _.find(usedOptions, ["value", value])?.title || "";
  }, [multiLevel, options, value]);

  return (
    <div style={{ width: "100%" }}>
      {title && (
        <Typography header>
          {title}
          {required && (
            <span style={{ color: theme.orange, marginLeft: "5px" }}>*</span>
          )}
        </Typography>
      )}

      {viewOnly ? (
        <Typography style={{ fontSize: "18px", color: theme.lightBlack }}>
          {viewOnlyValue}
        </Typography>
      ) : (
        <ThemeProvider theme={StyledTheme}>
          <Select
            displayEmpty
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            style={{ width: "100%" }}
            error={error}
          >
            <MenuItem value="" disabled>
              <em>{hintText || `=== ${t("common:selectOption")} ===`}</em>
            </MenuItem>
            {multiLevel ? 
              options?.reduce((acc, subheader) => {
                  acc.push(
                    <ListSubheader key={`subheader-${subheader.headerId}`}>{subheader.title}</ListSubheader>
                  );
                  acc.push(
                    ...subheader.options.map(item => (
                      <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                    ))
                  );
                  return acc;
                }, [])
              : options.map((item) => (
                <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
              )
            )}
          </Select>

          {error && (
            <Typography
              fontSize={12}
              color={theme.error2}
              style={{ marginLeft: 15, marginTop: 5 }}
            >
              {t(helperText || error)}
            </Typography>
          )}
        </ThemeProvider>
      )}
    </div>
  );
};

export default CommonSelect;
