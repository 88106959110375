import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Coupon from './Coupon';
import StepContainer from './StepContainer';
import OrderItem from './orderList/OrderItems';
import OrderListForm from './orderList/OrderListForm';
import PlanActivationProvider from './context/PlanActivationContext';

const OrderList = forwardRef(({
  data,
  tncData,
  step,
  currentStep,
  setStep,
  refetch,
  setIsPreview,
  onNext,
}, ref) => {
  const isStepActive = currentStep?.isActive || false;
  const { t } = useTranslation('checkout');

  return isStepActive ? (
    <PlanActivationProvider>
      <OrderListForm
        data={data}
        step={step}
        setStep={setStep}
        currentStep={currentStep}
        refetch={refetch}
        setIsPreview={setIsPreview}
        ref={ref}
        onNext={onNext}
      >
        <Container>
          <OrderItem tncData={tncData} />
          <Coupon data={data} refetch={refetch} />
        </Container>
      </OrderListForm>
  </PlanActivationProvider>
    
  ) : (
    <StepContainer
      step={step}
      setStep={setStep}
      currentStep={currentStep}
      title={t('orderInformation')}
      ref={ref}
    />
  );

});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default OrderList;
