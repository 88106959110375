import { createContext, useEffect, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { findIndex } from "lodash";
import {
  fetchCalendarConfig,
  fetchCheckout,
  fetchDeliveryConfig,
  fetchTNC,
} from "../../../apis";

import TNC from "../TNC";
import UserInfo from "../UserInfo";
import OrderList from "../OrderList";

const STEP_LIST = [
  {
    id: "planTNC",
    isPlanOnly: true,
    component: <TNC />,
  },
  {
    id: "userInfo",
    isPlanOnly: false,
    component: <UserInfo />,
  },
  {
    id: "previewOrder",
    isPlanOnly: false,
    component: <OrderList />,
  }
]

/**
 * @typedef {Object} CheckoutFormContextProps
 * @property {import("@tanstack/react-query").UseQueryResult<any>} quoteQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} tncQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} configQuery
 * @property {import("@tanstack/react-query").UseQueryResult<any>} calendarQuery
 * @property {boolean} isLoading
 * @property {boolean} isError
 * @property {boolean} isDeviceOnly
 * @property {boolean} hasDevice
 * @property {boolean} hasPlan
 */

/**
 * @type {CheckoutFormContextProps}
 */
const defaultContext = {}

export const CheckoutFormContext = createContext(defaultContext)

export default function CheckoutFormProvider({ children }) {

  const [step, setStep] = useState([]);
  // const [activeStepId, setActiveStepId] = useState(1);

  // Queries
  const queries = useQueries({
    queries: [
      {
        queryKey: ["fetchQuote"],
        queryFn: fetchCheckout,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always'
      },
      {
        queryKey: ["fetchTnc"],
        queryFn: fetchTNC,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always'
      },
      {
        queryKey: ["fetchDeliveryConfig"],
        queryFn: fetchDeliveryConfig,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["fetchCalendarConfig"],
        queryFn: fetchCalendarConfig,
        refetchOnWindowFocus: false
      },
    ],
  });

  const [quoteQuery, tncQuery, configQuery, calendarQuery] = queries;
  const isLoading = queries.some((query) => query.isLoading)
  const isError = queries.some((query) => query.isError)

  // Quote State
  const isDeviceOnly = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "plan"]) < 0
  }, [quoteQuery?.data?.data?.items])

  const hasDevice = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "product"]) >= 0
  }, [quoteQuery?.data?.data?.items])

  const hasPlan = useMemo(() => {
    return findIndex(quoteQuery?.data?.data?.items, ["type", "plan"]) >= 0
  }, [quoteQuery?.data?.data?.items])

  // Form Step
  const activeStepId = useMemo(() => findIndex(step, ["isActive", true]) + 1, [step])
  const activeStep = useMemo(() => step[activeStepId - 1], [step, activeStepId])

  useEffect(() => {
    const filterStep = STEP_LIST
                        .filter((i) => isDeviceOnly ? !i?.isPlanOnly : true)
                        .map((item, index) => ({
                          ...item,
                          isActive: index === 0,
                          isCompleted: false,
                        }))

    setStep(filterStep)
  }, [isDeviceOnly])

  return (
    <CheckoutFormContext.Provider value={{
      quoteQuery,
      tncQuery,
      configQuery,
      calendarQuery,
      isLoading,
      isError,

      isDeviceOnly,
      hasDevice,
      hasPlan,

      step,
      setStep,
      activeStep,
      activeStepId,
      // setActiveStepId,
    }}>
      {children}
    </CheckoutFormContext.Provider>
  );
}

