import React, { useContext, useEffect, useRef } from 'react';

import { Divider } from '@mui/material';
import StepGroup from '../../components/StepGroup';
import DevicePriceBar from '../../components/BottomBar/DevicePriceBar';
import LoadingIndicator from '../../components/LoadingIndicator';

import { CheckoutFormContext } from './context/CheckoutFormContext';
import useCheckoutGlobalTnc from './hooks/useCheckoutGlobalTnc';

const CheckoutForm = (props) => {
    const {
        onSubmit: onSubmitQuote,
    } = props;

    const { acceptedTNCStatus, setAcceptedTNCStatus } = useCheckoutGlobalTnc();

    const {
        quoteQuery: quoteData,
        tncQuery: tncData,
        configQuery: configData,
        calendarQuery: calendarData,

        isLoading,
        isError,

        step,
        setStep,
        activeStep,
        activeStepId,
    } = useContext(CheckoutFormContext);

    const stepRefs = useRef([]);

    console.log('==step', step)

    // Scroll to active step
    useEffect(function scrollOnActiveStepChange() {
        const activeStep = step?.findIndex((item) => item.isActive);
        if (activeStep > -1) {
            stepRefs.current[activeStep]?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [step]);

    const handleNext = () => {
        // Update step status
        const updatedStep = step.map((item, index) => {
            const stepId = index + 1;
            if (stepId === activeStepId) {
                return { ...item, isActive: false, isCompleted: true }
            } else if (stepId === activeStepId + 1) {
                return { ...item, isActive: true }
            } else {
                return { ...item, isActive: false }
            }
        })
        setStep(updatedStep);

        // check if current step is last step and all step completed
        const isAllStepCompleted = updatedStep.every((item) => item.isCompleted);
        const isLastStep = activeStepId === step.length;

        if (isAllStepCompleted && isLastStep) {
            onSubmitQuote && onSubmitQuote();
        }
    }

    if (isLoading) {
        return <LoadingIndicator />
    }

    if (isError) {
        console.log("==onError CheckoutForm", quoteData);
    }

    return (
        <>
        {/* Checkout Page Form StepGroup component */}
        <StepGroup data={step} />
        <Divider />

        {/* Form Content */}
        { step.map((item, index) => (
            item?.component ? React.cloneElement(item.component, {
                step: step,
                setStep: setStep,
                currentStep: item,
                data: quoteData?.data?.data,
                tncData: tncData?.data?.data,
                configData: configData?.data?.data,
                calendarData: calendarData?.data?.data,
                acceptedTNC: acceptedTNCStatus,
                setAcceptedTNC: setAcceptedTNCStatus,
                refetch: quoteData?.refetch,
                onNext: handleNext,
                ref: (ref) => stepRefs.current[index] = ref
            }) : null
        ))}
        
        {/* Bottom Total Price Bar */}
        <DevicePriceBar
            totalPrice={quoteData?.data?.data?.calculatedOrderPrice}
            onClick={handleNext}
            isCheckout
            disabled={step.some((item) => !item.isCompleted)}
            titleShowTotal
        />
        </>
    )
}

export default CheckoutForm;

